
import { defineComponent } from "vue-demi";
import Advertising from "@/components/owner/advertising/index.vue";

export default defineComponent({
  name: "OwnerAdvertisingView",
  components: {
    Advertising,
  },
});
